import React from "react";
import axios from "axios";

export default class Signup extends React.Component {
  state = {
    username: "",
    password: "",
    confirmPassword: ""
  };

  handleChange = event => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({
      [name]: value
    });
  };

  onSubmit = event => {
    if (this.state.password === this.state.confirmPassword) {
      axios
        .post("https://api.attentionspoilers.com/api/user/signup", {
          username: this.state.username,
          password: this.state.password
        }) // on recupere les datas rentrées par AXIOS
        .then(response => {
          if (response.data.valid === true) {
            this.props.history.push("/login"); // on va la page d'accueil
          }
        })
        .catch(err => {
          console.log(err);
        });
      event.preventDefault(); // ne pas ouvrir de nouvelle onglet/fenêtre lors de la connexion
    } else alert("Erreur confirmation password");
  };

  render() {
    return (
      <React.Fragment>
        <div>
          <img
            className="Logo"
            src="https://res.cloudinary.com/fj91/image/upload/v1581786240/AS/Header.png"
            alt=""
            height="150"
            width="300"
          />
          <div>
            <form onSubmit={this.onSubmit} className="formulaire">
              <label htmlFor="username">Username</label>
              <input
                className="formCase"
                id="username"
                name="username"
                type="text"
                value={this.state.username}
                onChange={this.handleChange}
              />
              <label htmlFor="password">Password</label>
              <input
                className="formCase"
                id="password"
                name="password"
                type="password"
                value={this.state.password}
                onChange={this.handleChange}
              />
              <label htmlFor="confirmPassword">Confirm password</label>
              <input
                className="formCase"
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                value={this.state.confirmPassword}
                onChange={this.handleChange}
              />
              <input className="createButton" type="submit" value="Valider" />
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
