import React from "react";
import axios from "axios";

export default class Login extends React.Component {
  state = {
    username: "",
    password: "",
    type: "",
    titleFilm: "",
    titleSerie: "",
    film: "",
    serie: "",
    episode: "",
    spoilFilm: " -  - attentionspoilers.com",
    spoilEpisode: " -  - attentionspoilers.com",
    price: "0.99",
    currency: "eur",
    userLogin: false,
    arrayOfDataSerie: null,
    arrayOfDataFilm: null,
    arrayOfDataEpisode: [{ label: " ", _id: "0" }],
    filmId: null,
    episodeId: null,
    isLoading: false,
    addFilm: false,
    addSerie: false,
    addEpisode: false,
    changeFilm: false,
    changeEpisode: false,
    stats: false,
    statsSpoilsEnvoyes: "0",
  };

  handleChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({
      [name]: value,
    });
  };

  handleSelectAddTitleFilm = (event) => {
    this.setState(
      {
        titleFilm: event.target.value,
        spoilFilm: " - " + event.target.value + " - attentionspoilers.com",
      },
      () =>
        console.log(
          "TITLE FILM SELECT ",
          this.state.titleFilm,
          this.state.spoilFilm
        )
    );
  };

  handleSelectAddTitleSerie = (event) => {
    let serie = null;
    for (let i = 0; i < this.state.arrayOfDataSerie.length; i++) {
      if (event.target.value === this.state.arrayOfDataSerie[i]._id) {
        serie = this.state.arrayOfDataSerie[i].label;
      }
    }
    this.setState(
      {
        serie: event.target.value,
        spoilEpisode: " - " + serie + " - attentionspoilers.com",
      },
      () =>
        console.log(
          "TITLE SERIE SELECT ",
          this.state.titleSerie,
          this.state.spoilEpisode
        )
    );
  };

  onLogin = (event) => {
    axios
      .get("https://api.attentionspoilers.com/api/user/login", {
        params: {
          username: this.state.username,
          password: this.state.password,
        },
      }) // on recupere les datas rentrées par AXIOS
      .then((response) => {
        if (response.data.valid === true) {
          Promise.all([this.requestSerie(), this.requestFilm()])
            .then((response) => {
              console.log("PROMISE OK");
              this.setState({
                userLogin: true,
                isLoading: true,
                password: null,
                username: null,
              });
            })
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => {
        console.log(err);
      });
    event.preventDefault(); // ne pas ouvrir de nouvelle onglet/fenêtre lors de la connexion
  };

  requestSerie = () => {
    return new Promise((resolve, reject) => {
      axios
        .get("https://api.attentionspoilers.com/api/spoil/requestserie")
        .then((response) => {
          if (response.data) {
            this.setState(
              { arrayOfDataSerie: response.data },
              () => console.log("DATA SERIE", this.state.arrayOfDataSerie),
              resolve(true)
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  handleSelectChangeSerie = (event) => {
    this.setState(
      {
        titleSerie: event.target.value,
      },
      () => console.log("SERIE SELECT ", this.state.serie)
    );
  };

  handleSelectChangeSerieForEpisode = (event) => {
    let value = event.target.value;
    this.setState(
      {
        serie: value,
      },
      () => console.log("SERIE SELECT ", this.state.serie),
      this.requestEpisode(value)
    );
  };

  requestEpisode = (value) => {
    axios
      .get("https://api.attentionspoilers.com/api/spoil/spoilrequestepisode", {
        params: {
          serie: value,
        },
      })
      .then((response) => {
        if (response.data) {
          this.setState({ arrayOfDataEpisode: response.data }, () =>
            console.log("DATA EPISODES", this.state.arrayOfDataEpisode)
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleSelectChangeEpisode = (event) => {
    let value = event.target.value;
    if (value !== "0") {
      for (let i = 0; i < this.state.arrayOfDataEpisode.length; i++) {
        if (value === this.state.arrayOfDataEpisode[i]._id) {
          var episodeCut = this.state.arrayOfDataEpisode[i].label;
          this.setState(
            {
              episode: episodeCut.slice(0, -8),
              spoilEpisode: this.state.arrayOfDataEpisode[i].value,
              price: this.state.arrayOfDataEpisode[i].price,
              episodeId: this.state.arrayOfDataEpisode[i]._id,
              currency: this.state.arrayOfDataEpisode[i].currency,
            },
            () => console.log("FILM SELECT ", this.state)
          );
        }
      }
    }
  };

  changeEpisode = (event) => {
    console.log("CHANGE FILM ", this.state);
    axios
      .post("https://api.attentionspoilers.com/api/spoil/changeepisode", {
        title: this.state.episode + " - " + this.state.price + "€",
        spoil: this.state.spoilEpisode,
        price: this.state.price,
        currency: this.state.currency,
        episodeId: this.state.episodeId,
      })
      .then((response) => {
        if (response.data.valid === true) {
          alert("Episode modifié avec succès.");
        }
      })
      .catch((err) => {
        console.log(err);
      });
    event.preventDefault(); // ne pas ouvrir de nouvelle onglet/fenêtre lors de la connexion
  };

  requestFilm = () => {
    return new Promise((resolve, reject) => {
      axios
        .get("https://api.attentionspoilers.com/api/spoil/spoilrequestfilm")
        .then((response) => {
          if (response.data) {
            this.setState(
              { arrayOfDataFilm: response.data },
              () => console.log("DATA FILM", this.state.arrayOfDataFilm),
              resolve(true)
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  handleSelectChangeFilm = (event) => {
    let value = event.target.value;
    for (let i = 0; i < this.state.arrayOfDataFilm.length; i++) {
      if (value === this.state.arrayOfDataFilm[i]._id) {
        var filmCut = this.state.arrayOfDataFilm[i].label;
        this.setState(
          {
            film: filmCut.slice(0, -8),
            spoilFilm: this.state.arrayOfDataFilm[i].value,
            price: this.state.arrayOfDataFilm[i].price,
            filmId: this.state.arrayOfDataFilm[i]._id,
            currency: this.state.arrayOfDataFilm[i].currency,
          },
          () => console.log("FILM SELECT ", this.state)
        );
      }
    }
  };

  changeFilm = (event) => {
    console.log("CHANGE FILM ", this.state);
    axios
      .post("https://api.attentionspoilers.com/api/spoil/changefilm", {
        title: this.state.film + " - " + this.state.price + "€",
        spoil: this.state.spoilFilm,
        price: this.state.price,
        currency: this.state.currency,
        filmId: this.state.filmId,
      })
      .then((response) => {
        if (response.data.valid === true) {
          alert("Film modifié avec succès.");
        }
      })
      .catch((err) => {
        console.log(err);
      });
    event.preventDefault(); // ne pas ouvrir de nouvelle onglet/fenêtre lors de la connexion
  };

  addFilm = (event) => {
    console.log("ADD FILM ", this.state);
    axios
      .post("https://api.attentionspoilers.com/api/spoil/newspoil", {
        type: "film",
        title: this.state.titleFilm + " - " + this.state.price + "€",
        spoil: this.state.spoilFilm,
        price: this.state.price,
        currency: this.state.currency,
      })
      .then((response) => {
        if (response.data.valid === true) {
          alert("Film ajouté avec succès.");
        }
      })
      .catch((err) => {
        console.log(err);
      });
    event.preventDefault(); // ne pas ouvrir de nouvelle onglet/fenêtre lors de la connexion
  };

  addSerie = (event) => {
    console.log("ADD SERIE ", this.state);
    axios
      .post("https://api.attentionspoilers.com/api/spoil/newspoil", {
        type: "serie",
        title: this.state.titleSerie,
      })
      .then((response) => {
        if (response.data.valid === true) {
          this.requestSerie();
          alert("Série ajouté avec succès.");
        }
      })
      .catch((err) => {
        console.log(err);
      });
    event.preventDefault(); // ne pas ouvrir de nouvelle onglet/fenêtre lors de la connexion
  };

  addEpisode = (event) => {
    console.log("ADD EPISODE ", this.state);
    axios
      .post("https://api.attentionspoilers.com/api/spoil/newspoil", {
        type: "episode",
        serie: this.state.serie,
        episode: this.state.episode + " - " + this.state.price + "€",
        spoil: this.state.spoilEpisode,
        price: this.state.price,
        currency: this.state.currency,
      })
      .then((response) => {
        if (response.data.valid === true) {
          alert("Episode ajouté avec succès.");
        }
      })
      .catch((err) => {
        console.log(err);
      });
    event.preventDefault(); // ne pas ouvrir de nouvelle onglet/fenêtre lors de la connexion
  };

  spoilEpisodeLength = () => {
    var spoilEpisode = this.state.spoilEpisode.length + "  /  160";
    return spoilEpisode;
  };

  spoilFilmLength = () => {
    var spoilFilm = this.state.spoilFilm.length + "  /  160";
    return spoilFilm;
  };

  buttonAddFilm = () => {
    this.setState({
      addFilm: true,
      addSerie: false,
      addEpisode: false,
      changeFilm: false,
      changeEpisode: false,
      stats: false,
    });
  };

  buttonAddSerie = () => {
    this.setState({
      addFilm: false,
      addSerie: true,
      addEpisode: false,
      changeFilm: false,
      changeEpisode: false,
      stats: false,
    });
  };

  buttonAddEpisode = () => {
    this.setState({
      addFilm: false,
      addSerie: false,
      addEpisode: true,
      changeFilm: false,
      changeEpisode: false,
      stats: false,
    });
  };

  buttonChangeFilm = () => {
    this.setState({
      addFilm: false,
      addSerie: false,
      addEpisode: false,
      changeFilm: true,
      changeEpisode: false,
      stats: false,
    });
  };

  buttonChangeEpisode = () => {
    this.setState({
      addFilm: false,
      addSerie: false,
      addEpisode: false,
      changeFilm: false,
      changeEpisode: true,
      stats: false,
    });
  };

  buttonStats = () => {
    console.log("COUNTSENDSPOILS");
    axios
      .get("https://api.attentionspoilers.com/api/spoil/countsms")
      .then((response) => {
        console.log(response);
        if (response.data) {
          this.setState(
            {
              statsSpoilsEnvoyes: response.data,
              addFilm: false,
              addSerie: false,
              addEpisode: false,
              changeFilm: false,
              changeEpisode: false,
              stats: true,
            },
            () =>
              console.log(
                "DATA NOMBRE SPOILS ENVOYES",
                this.state.statsSpoilsEnvoyes
              )
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  choiceButton = () => {
    let arrayOfDataSerie = this.state.arrayOfDataSerie;
    let optionsSerie = arrayOfDataSerie.map((data) => (
      <option key={data.label} value={data._id}>
        {data.label}
      </option>
    ));
    let arrayOfDataFilm = this.state.arrayOfDataFilm;
    let optionsFilm = arrayOfDataFilm.map((data) => (
      <option key={data.label} value={data._id}>
        {data.label}
      </option>
    ));
    let arrayOfDataEpisode = this.state.arrayOfDataEpisode;
    let optionsEpisode = arrayOfDataEpisode.map((data) => (
      <option key={data.label} value={data._id} name={data.label}>
        {data.label}
      </option>
    ));
    if (this.state.addFilm === true) {
      return (
        <div>
          <p>
            <strong>AJOUTER UN FILM</strong>
          </p>
          <form onSubmit={this.addFilm} className="formulaire">
            <label htmlFor="titleFilm">Titre film</label>
            <input
              className="formCase"
              id="titleFilm"
              name="titleFilm"
              type="text"
              value={this.state.titleFilm}
              onChange={this.handleSelectAddTitleFilm}
            />
            <label htmlFor="spoilFilm">Contenu du sms</label>
            {this.spoilFilmLength()}
            <input
              className="formCase"
              id="spoilFilm"
              name="spoilFilm"
              type="text"
              maxLength={160}
              value={this.state.spoilFilm}
              onChange={this.handleChange}
            />
            <label htmlFor="price">Prix du sms - par default 0.99</label>
            <input
              className="formCase"
              id="price"
              name="price"
              type="text"
              value={this.state.price}
              onChange={this.handleChange}
            />
            <label htmlFor="currency">Devise - par défaut laisser eur</label>
            <input
              className="formCase"
              id="currency"
              name="currency"
              type="text"
              value={this.state.currency}
              onChange={this.handleChange}
            />
            <input
              className="createButton"
              type="submit"
              value="Ajouter le film"
            />
          </form>
        </div>
      );
    } else if (this.state.addSerie === true) {
      return (
        <div>
          <p>
            <strong>AJOUTER UNE SERIE</strong>
          </p>
          <form onSubmit={this.addSerie} className="formulaire">
            <label htmlFor="titleSerie">Titre de la série</label>
            <input
              className="formCase"
              id="titleSerie"
              name="titleSerie"
              type="text"
              value={this.state.titleSerie}
              onChange={this.handleSelectChangeSerie}
            />
            <input
              className="createButton"
              type="submit"
              value="Ajouter la série"
            />
          </form>
        </div>
      );
    } else if (this.state.addEpisode === true) {
      return (
        <div>
          <p>
            <strong>AJOUTER UN EPISODE</strong>
          </p>
          <form onSubmit={this.addEpisode} className="formulaire">
            <label htmlFor="list">Choisir la série</label>
            <select
              name="list"
              className="customSearch"
              onChange={this.handleSelectAddTitleSerie}
            >
              <option>Sélectionner la série</option>
              {optionsSerie}
            </select>
            <label htmlFor="episode">Numéro d'episode - Ex: Episode 1</label>
            <input
              className="formCase"
              id="episode"
              name="episode"
              type="text"
              value={this.state.episode}
              onChange={this.handleChange}
            />
            <label htmlFor="spoilEpisode">Contenu du sms</label>
            {this.spoilEpisodeLength()}
            <input
              className="formCase"
              id="spoilEpisode"
              name="spoilEpisode"
              type="text"
              maxLength={160}
              value={this.state.spoilEpisode}
              onChange={this.handleChange}
            />
            <label htmlFor="price">Prix du sms - par default 0.99</label>
            <input
              className="formCase"
              id="price"
              name="price"
              type="text"
              value={this.state.price}
              onChange={this.handleChange}
            />
            <label htmlFor="currency">Devise - par défaut laisser eur</label>
            <input
              className="formCase"
              id="currency"
              name="currency"
              type="text"
              value={this.state.currency}
              onChange={this.handleChange}
            />
            <input
              className="createButton"
              type="submit"
              value="Ajouter l'épisode"
            />
          </form>
        </div>
      );
    } else if (this.state.changeFilm === true) {
      return (
        <div>
          <p>
            <strong>MODIFIER UN FILM</strong>
          </p>
          <form onSubmit={this.changeFilm} className="formulaire">
            <label htmlFor="list">Choisir le film</label>
            <select
              name="list"
              className="customSearch"
              onChange={this.handleSelectChangeFilm}
            >
              <option>Sélectionner le film</option>
              {optionsFilm}
            </select>
            <label htmlFor="episode">Titre du film</label>
            <input
              className="formCase"
              id="film"
              name="film"
              type="text"
              value={this.state.film}
              onChange={this.handleChange}
            />
            <label htmlFor="spoilEpisode">Contenu du sms</label>
            {this.spoilFilmLength()}
            <input
              className="formCase"
              id="spoilFilm"
              name="spoilFilm"
              type="text"
              maxLength={160}
              value={this.state.spoilFilm}
              onChange={this.handleChange}
            />
            <label htmlFor="price">Prix du sms - par default 0.99</label>
            <input
              className="formCase"
              id="price"
              name="price"
              type="text"
              value={this.state.price}
              onChange={this.handleChange}
            />
            <label htmlFor="currency">Devise - par défaut laisser eur</label>
            <input
              className="formCase"
              id="currency"
              name="currency"
              type="text"
              value={this.state.currency}
              onChange={this.handleChange}
            />
            <input
              className="createButton"
              type="submit"
              value="Modifier le film"
            />
          </form>
        </div>
      );
    } else if (this.state.changeEpisode === true) {
      return (
        <div>
          <p>
            <strong>MODIFIER UN EPISODE</strong>
          </p>
          <form onSubmit={this.changeEpisode} className="formulaire">
            <label htmlFor="list">Choisir la série</label>
            <select
              name="list"
              className="customSearch"
              onChange={this.handleSelectChangeSerieForEpisode}
            >
              <option>Sélectionner la série</option>
              {optionsSerie}
            </select>
            <label htmlFor="list">Choisir un épisode</label>
            <select
              name="list"
              className="customSearch"
              onChange={this.handleSelectChangeEpisode}
            >
              <option>Sélectionner un épisode</option>
              {optionsEpisode}
            </select>
            <label htmlFor="episode">Numéro d'episode - Ex: Episode 1</label>
            <input
              className="formCase"
              id="episode"
              name="episode"
              type="text"
              value={this.state.episode}
              onChange={this.handleChange}
            />
            <label htmlFor="spoilEpisode">Contenu du sms</label>
            {this.spoilEpisodeLength()}
            <input
              className="formCase"
              id="spoilEpisode"
              name="spoilEpisode"
              type="text"
              maxLength={160}
              value={this.state.spoilEpisode}
              onChange={this.handleChange}
            />
            <label htmlFor="price">Prix du sms - par default 0.99</label>
            <input
              className="formCase"
              id="price"
              name="price"
              type="text"
              value={this.state.price}
              onChange={this.handleChange}
            />
            <label htmlFor="currency">Devise - par défaut laisser eur</label>
            <input
              className="formCase"
              id="currency"
              name="currency"
              type="text"
              value={this.state.currency}
              onChange={this.handleChange}
            />
            <input
              className="createButton"
              type="submit"
              value="Modifier l'épisode"
            />
          </form>
        </div>
      );
    } else if (this.state.stats === true) {
      return (
        <div>
          <p>Nombre de spoils envoyé: {this.state.statsSpoilsEnvoyes}</p>
        </div>
      );
    }
  };

  render() {
    if (this.state.userLogin === false) {
      return (
        <React.Fragment>
          <div className="content">
            <img
              className="Logo"
              src="https://res.cloudinary.com/fj91/image/upload/v1581786240/AS/Header.png"
              alt=""
              height="150"
              width="300"
            />
            <div>
              <form onSubmit={this.onLogin} className="formulaire">
                <label htmlFor="username">Username</label>
                <input
                  className="formCase"
                  id="username"
                  name="username"
                  type="text"
                  value={this.state.username}
                  onChange={this.handleChange}
                />
                <label htmlFor="password">Password</label>
                <input
                  className="formCase"
                  id="password"
                  name="password"
                  type="password"
                  value={this.state.password}
                  onChange={this.handleChange}
                />
                <input className="createButton" type="submit" value="Valider" />
              </form>
            </div>
          </div>
        </React.Fragment>
      );
    } else if (this.state.userLogin === true && this.state.isLoading === true) {
      return (
        <React.Fragment>
          <div className="content">
            <img
              className="Logo"
              src="https://res.cloudinary.com/fj91/image/upload/v1581786240/AS/Header.png"
              alt=""
              height="150"
              width="300"
            />
            <div>
              <button className="button" onClick={this.buttonAddFilm}>
                AJOUTER UN FILM
              </button>
              <button className="button" onClick={this.buttonAddSerie}>
                AJOUTER UNE SERIE
              </button>
              <button className="button" onClick={this.buttonAddEpisode}>
                AJOUTER UN EPISODE
              </button>
              <button className="button" onClick={this.buttonChangeFilm}>
                MODIFIER UN FILM
              </button>
              <button className="button" onClick={this.buttonChangeEpisode}>
                MODIFIER UN EPISODE
              </button>
              <button className="button" onClick={this.buttonStats}>
                STATS
              </button>
            </div>
            {this.choiceButton()}
          </div>
        </React.Fragment>
      );
    }
  }
}
