import React from "react";
import {
  BrowserRouter as Router,
  Route /* Link , Switch */,
} from "react-router-dom";
import Login from "./containers/Login";
import Signup from "./containers/Signup";
import "./App.css";

export default class App extends React.Component {
  render() {
    return (
      <Router basename={window.location.pathname || ""}>
        <React.Fragment>
          <Route exact path="/" component={Login} />
          {/* <Route path="/signup" component={Signup} /> */}
        </React.Fragment>
      </Router>
    );
  }
}
